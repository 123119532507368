* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body {
  background: url('/img/tile.png') #333333;
  font-family: 'Maven Pro', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bar {
  background: -webkit-repeating-linear-gradient(315deg, rgba(254, 183, 34, 0), rgba(254, 183, 34, 0) 30px, rgba(17, 17, 17, 1) 30px, rgb(17, 17, 17) 60px), -webkit-linear-gradient(bottom, rgb(190, 138, 25), rgba(254, 183, 34, 1));
  background: repeating-linear-gradient(135deg, rgba(254, 183, 34, 0), rgba(254, 183, 34, 0) 30px, rgba(17, 17, 17, 1) 30px, rgb(17, 17, 17) 60px), linear-gradient(to top, rgb(190, 138, 25), rgba(254, 183, 34, 1));
  height: 30px;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5), inset 0 10px 10px rgba(0, 0, 0, 0.5);
}

.content {
  background: rgba(56, 57, 59, 0.5);
  max-width: 800px;
  border-radius: 7px 7px 7px 7px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5), inset 0 0 20px rgba(255, 255, 255, 0.07);
  margin: 30px auto 0 auto;
}

header .cover {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  padding-left: 100px;
  background-color: #38393b;
  background-image: url('/img/zk-cover-full.jpg');
  background-position: right;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

header .cover img {
  -webkit-filter: drop-shadow(0 0 10px #000000);
          filter: drop-shadow(0 0 10px #000000)
}

nav {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 35px;
  font-size: 120%;
  height: 35px;
}

footer {
  margin: 20px auto;
  max-width: 800px;
  padding: 0 30px;
  color: rgba(255, 255, 255, 0.5);
}

footer .zk-forever:after {
  content: ' 2004\2013' attr(data-date);
}

footer .zk-forever:hover:after {
  content: 'Drakir: demorec d;)';
}

footer .zk-forever:hover {
  color: rgba(61, 255, 13, 1);
}

nav li {
  display: inline-block;
}

a {
  color: rgb(190, 138, 25);
}

a:hover {
  text-decoration: none;
}

nav a {
  display: inline-block;
  text-decoration: none;
  padding: 0 17px;
}

nav a:hover {
  text-decoration: underline;
  color: rgba(254, 183, 34, 1);
}

main {
  position: relative;
  padding: 60px 30px 25px 30px;
}

main p:not(:last-child) {
  line-height: 120%;
  margin-bottom: 20px;
}

main b {
  font-weight: lighter;
  color: #FFFFFF;
}

main h3:not(:last-child) {
  margin-bottom: 20px;
}

.user-list {
  -webkit-column-width: 120px;
  column-width: 120px;
  list-style: none;
  text-align: center;
}

.user-list li {
  line-height: 150%;
}

.media {
  text-align: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery img {
  width: 100%;
  min-height: 150px;
  border-radius: 7px;
  opacity: 0.0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.gallery img.loaded {
  cursor: pointer;
  border: 0;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  min-height: auto;
  opacity: 1.0;
}

.gallery img:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
}

.gallery .placeholder {
  height: 150px;
}

.gallery .image-container {
  flex-grow: 1;
  flex-basis: 150px;
  margin: 4px;
}

.lightbox {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
}

.lightbox .lightbox-content {
  margin: auto;
  display: inline-block;
  max-width: 80%;
  position: relative;
}

.lightbox-content img {
  max-width: 100%;
}

@media only screen and (max-width: 700px) {
  .lightbox img {
    width: 100%;
  }
}

.close {
  font-family: Verdana, sans-serif;
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.close:hover, .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.prev, .next {
  font-family: Verdana, sans-serif;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 50px;
  padding: 16px;
  color: #f1f1f1;
  font-weight: bold;
  font-size: 30px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.prev {
  left: 0;
  border-radius: 0 3px 3px 0;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.image-progress {
  color: #f1f1f1;
  font-size: 12px;
  padding: 12px 12px;
  position: absolute;
  bottom: 0;
}

.pagination {
  display: inline-block;
  margin: 10px 0 0 0;
}

.pagination a {
  color: rgba(255, 255, 255, 0.5);
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  margin: 0 2px;
  border: 1px solid rgba(255, 255, 255, 0);
}

.pagination a.active {
  color: rgb(190, 138, 25);
  border: 1px solid rgb(190, 138, 25);
}

.pagination a:hover:not(.active) {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
